import React, { useState, useEffect } from "react";
import { ViewershipMap, ViewershipTableChild } from "../../../api/api";
import { useDispatch } from "react-redux";
import { ChannelDataAction, MapDataAction } from "../../../Redux/slices";
import { TimeConverter, formatNumber } from "../../../service/commonFunc";
import rightarrow  from "../../../assets/images/caret-small-right.svg"
import leftarrow  from "../../../assets/images/caret-small-left.svg"
import Loader from "../../../component/Loader/Loader";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
// import { CartesianGrid, LineChart, XAxis, Line, Tooltip } from "recharts";

function AccordionChild({ data, filter, clickedData, graphView}) {
  const [programData, setProgramData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalShowLength, setTotalShowLength] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState([]);
  const [showLoaders, setshowLoaders] = useState(false);
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({
    plugins: {
      tooltip: {
        enabled: true, // Enable tooltips
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          }
        }
      },
      legend: {
        display: true,
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Program Name'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Information'
        }
      }
    }
  });

  useEffect(() => {
    const xdata = [];
    const ydata = [];
    const ydata2 = [];
    const ydata3 = [];
    const ydata4 = [];
    const ydata5 = [];
    programData.map((data) => {
      xdata.push(data.showName);
      ydata.push(data.numberOfActiveUsers);
      ydata2.push(data.totalWatchHours);
      // ydata3.push(data.averageWatchTimePerSession);
      // ydata4.push(data.averageWatchTimePerUser);
      ydata3.push(data.totalAdImpression);
    });
    setXAxis(xdata);
    setYAxis(ydata);
    setChartData({
      labels: xdata,
      datasets: [
        {
          label: 'Number of Active Users',
          data: ydata,
          fill: false,
          backgroundColor: 'rgb(0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0.7)',
        }, {
          label: 'Total watch hours',
          data: ydata2,
          fill: false,
          backgroundColor: 'rgb(0, 50, 200)',
          borderColor: 'rgba(0, 50, 200, 0.7)',
        },
        //  {
        //   label: 'Average watch time per session',
        //   data: ydata3,
        //   fill: false,
        //   backgroundColor: 'rgb(0, 0, 0)',
        //   borderColor: 'rgba(0, 0, 0, 0.7)',
        // }, {
        //   label: 'Average watch time per user',
        //   data: ydata4,
        //   fill: false,
        //   backgroundColor: 'rgb(0, 0, 0)',
        //   borderColor: 'rgba(0, 0, 0, 0.7)',
        // }, 
        {
          label: 'Total ad impression',
          data: ydata3,
          fill: false,
          backgroundColor: 'rgb(255, 255, 0)',
          borderColor: 'rgba(255, 255, 0, 0.7)',
        },
      ],
      options: {
        scales: {
          y: {
            beginAtZero: true, // This ensures the y-axis starts at 0
            ticks: {
              precision: 0 // This can be used to avoid decimal values on the y-axis
            }
          }
        }
      }
    })
    // xAxis={[{ scaleType: 'point', data: xAxis }]}
    // series={[
    //   {
    //     data: yAxis,
    //   },
    // ]}
  }, [programData]);
 
  // Update the API call parameters when currentPage or itemsPerPage change
  useEffect(() => {
    const offset = (currentPage - 1) * itemsPerPage;
    const limit = itemsPerPage;
    const ViewershipTableChildData = {
      viewType: filter?.viewType,
      viewKey: data?.viewKey,
      duration: filter?.duration,
      offset,
      limit,
      sortBy: clickedData?.key,
      sortOrder: clickedData?.sort
    };
    // console.log("hello", clickedData );
    (async () => {
      const childdata = await ViewershipTableChild(ViewershipTableChildData,setshowLoaders);
      setProgramData(childdata?.data.data.rows);
      setTotalShowLength(childdata?.data.data.totalResults);
    })();
 
    if(filter?.viewType == "CHANNEL" && filter?.duration == "DAY"){
      // console.log("SAS",filter?.viewType);
      mapRenderData()

    }

  }, [currentPage, itemsPerPage, data, filter, clickedData]);
  
  const mapRenderData = async () => {
    const ViewershipMapData = {
      channelName: data?.viewKey
    };
    
    const MapData = await ViewershipMap(ViewershipMapData);
    if(MapData.data.success == true){
      dispatch(ChannelDataAction(data));
   dispatch(MapDataAction(MapData?.data));
   }
  };

  useEffect(() => {

    // Calculate the maximum selectable value in intervals of 10
    const maxSelectableValue = Math.ceil(totalShowLength / itemsPerPage) * itemsPerPage;

    // Generate the dynamic options array based on maxSelectableValue
    const newOptions = [...Array(maxSelectableValue / itemsPerPage)].map(
      (_, index) => (index + 1) * itemsPerPage
    );
   
    
    setOptions(newOptions.length >= 3 ? newOptions.slice(0, 3) : newOptions);
  }, [totalShowLength]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    // Ensure the selected value is not greater than 30
    const clampedItemsPerPage = Math.min(newItemsPerPage, 30);
    setItemsPerPage(clampedItemsPerPage);
    setCurrentPage(1); // Reset currentPage to 1 when changing the number of items per page
  };

  const totalPages = Math.ceil(totalShowLength / itemsPerPage);

  const renderPaginationButtons = () => {
    const buttons = [];
    const maxVisibleButtons = 3; // Maximum number of visible buttons

    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active" : ""}
          >
            {i}
          </button>
        );
      }
    } else {
      if (currentPage <= maxVisibleButtons - 1) {
        for (let i = 1; i <= maxVisibleButtons; i++) {
          buttons.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={currentPage === i ? "active" : ""}
            >
              {i}
            </button>
          );
        }
        buttons.push(<span key="ellipsis1">...</span>);
        buttons.push(
          <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        );
      } else if (currentPage >= totalPages - (maxVisibleButtons - 2)) {
        buttons.push(
          <button key={1} onClick={() => handlePageChange(1)}>
            1
          </button>
        );
        buttons.push(<span key="ellipsis1">...</span>);
        for (let i = totalPages - (maxVisibleButtons - 2); i <= totalPages; i++) {
          buttons.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={currentPage === i ? "active" : ""}
            >
              {i}
            </button>
          );
        }
      } else {
        buttons.push(
          <button key={1} onClick={() => handlePageChange(1)}>
            1
          </button>
        );
        buttons.push(<span key="ellipsis1">...</span>);
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          buttons.push(
            <button
              key={i}
              onClick={() => handlePageChange(i)}
              className={currentPage === i ? "active" : ""}
            >
              {i}
            </button>
          );
        }
        buttons.push(<span key="ellipsis2">...</span>);
        buttons.push(
          <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </button>
        );
      }
    }

    return buttons;
  };

  const showPagination = true
  console.log('programData',programData);
  return (

    <div className="inner">
      { showLoaders && <Loader />}
      {graphView ?
      <>
        {xAxis.length > 0 && 
        <Line data={chartData} options={chartOptions} />
        // <LineChart
        //   xAxis={[{ scaleType: 'point', data: xAxis, 
        //     valueFormatter: (value) => {
        //       // Insert a line break after a certain number of characters
        //       // This is a simple example; you might need a more complex logic
        //       return value.length > 5 ? value.slice(0, 5) + '\n' + value.slice(6,10) + '...' : value;
        //     }
        //   }]}
        //   series={[
        //     {
        //       data: yAxis,
        //       color: '#fdb462',
        //       className: 'white',
        //       valueFormatter: (v, { dataIndex }) => {
        //         const value = xAxis[dataIndex];
        //         return `${value}.`;
        //       },
        //     },
        //   ]}
        //   width={700}
        //   height={300}
        // >
        //   <XAxis dataKey={xAxis}/>
        //   <Tooltip />
        //   <CartesianGrid stroke="#f5f5f5" />
        //   <Line type="monotone" dataKey="uv" stroke="#ff7300" yAxisId={0} />
        //   <Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} />
        // </LineChart> 
        }
      </> :
      <div className="table-content">
        {programData.map((data, index) => (
          <div key={index} className="table-row">
            <div className="table-col name">{data.showName}</div>
            <div className="table-col">{formatNumber(data.numberOfActiveUsers)}</div>
            <div className="table-col">{formatNumber(data.totalWatchHours?.toFixed(2))}</div>
            <div className="table-col">{TimeConverter(data.averageWatchTimePerUser)}</div>
            <div className="table-col">{TimeConverter(data.averageWatchTimePerSession)}</div>
            {/* <div className="table-col">{data.totalAdImpression}</div> */}
          </div>
        ))}
      </div>}
      
      {/* */}
      
      {showPagination && !graphView && (
        <div className="pagination pagination-table"  >
          <div className="items-per-page">
            <label >Items per page:</label>
            <select className="selectboxpage" value={itemsPerPage} onChange={handleItemsPerPageChange}>
              {options.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
              {/* <option value={totalShowLength}>All</option> */}
            </select>

            
          </div>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1} 
          >
            <img src={leftarrow} alt="rightarrow" />
          </button>
          {renderPaginationButtons()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages} 
          >
             <img src={rightarrow} alt="rightarrow" />
          </button>
          
        </div>
      )}

    </div>
  
  );
}

export default AccordionChild;
