import React, { useState, useMemo , createPortal, useEffect } from 'react';
import { useNavigate, Link, useLocation, Navigate } from 'react-router-dom';
import "../../assets/css/style.scss"
import backicon from "../../assets/images/arrow-prev.svg"

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_METADATA, POST_METADATA_BULK, Metadataupdate_URL } from "../../service/API_URL";
import { getInstantData, postInstantData, deleteInstantData } from "../../api/apiMethod";
import styles from './editable-grid.module.scss';
import 'react-data-grid/lib/styles.css';
import DataGrid, { textEditor } from 'react-data-grid';
import {RenderDropdown} from './renderDropdown';
import {MultiDropdown} from './multi-select';
import {DatePickerGrid} from './datepicker'
import {TimePickerGrid} from './timepicker'
import {itemViewer} from './item-viewer';
import { useSelector } from 'react-redux';
import {TextEditorCustom} from './text-editor';
import { useDispatch } from "react-redux";
import { MetaGridAction } from "../../Redux/slices";
import plusicon from '../../assets/images/plus-icon.png';
import { permissionDetails } from '../../utility/localStoageData';
const genre = [
  'GN-DRA', 'GN-COM', 'GN-SCF', 'GN-CRI', 'GN-ROM'
]
// const columns = [
//   { key: 'id', name: 'ID' },
//   { key: 'title', name: 'Title' }
// ];

// const rows = [
//   { id: 0, title: 'Example',duration: 'asdf' },
//   { id: 1, title: 'Demo',duration: 'asdf' },
// ];
// function RenderDropdown({ row, onRowChange }) {
//   return (
//     <select
//     className={textEditorClassname}
//       value={row.genre}
//       onChange={(event) => onRowChange({ ...row, genre: event.target.value }, true)}
//       autoFocus
//     >
//       {genre.map((gen) => (
//         <option key={gen} value={gen}>
//           {gen}
//         </option>
//       ))}
//     </select>
//   );
// }

  const direction = 'ASC'
  const dateFormatter = new Intl.DateTimeFormat(navigator.language);
  const currencyFormatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency: 'eur'
  });

    function getColumns(
    countries,
    genre,
    direction,
    AudioLanguageList
  ) {
    return [
      {
        key: 'programId',
        name: 'Prog. ID',
        width: '120px',
        frozen: true,
        renderEditCell: TextEditorCustom
      },
      {
        key: 'title',
        name: 'Title',
        width: '120px',
        frozen: true,
        renderEditCell: textEditor,
      },
      {
        key: 'duration',
        name: 'Duration',
        type: 'text',
        width: '120px',
        editable: true,
        renderEditCell: TimePickerGrid
      },
      {
        key: 'shortSynopsis',
        name: 'Short Synopsis',
        renderEditCell: textEditor,
        width: '350px',
        resizable: true,
        className: 'myClassThatEnablesTextWrapping'
      },
      {
        key: 'actors',
        name: 'Actors',
        width: '150px',
        renderEditCell: textEditor
      },
      {
        key: 'directors',
        name: 'Directors',
        width: '150px',
        renderEditCell: textEditor
      },
      {
        key: 'releaseDate',
        name: 'Release Date',
        width: '100px',
        renderCell({row}) {
          if(!row.releaseDate || row.releaseDate?.length<1) {
            return '';
          }
          const isDate = (new Date(row.releaseDate) !== "Invalid Date") && !isNaN(new Date(row.releaseDate));
          const Dates =  (isDate) ? new Date(row.releaseDate) : new Date();
          return dateFormatter.format(Dates);
        },
        renderEditCell: DatePickerGrid
      },
      {
        key: 'originalLanguage',
        name: 'Orignal Language',
        width: '120px',
        renderCell: ({row}) => {
        //  if(!Array.isArray(row?.['originalLanguage'])) return;
        // console.log(row?.['originalLanguage'])
          const country =  AudioLanguageList?.filter(function(value) {
      
            return row?.['originalLanguage']?.name == value?.name;
          });

          // console.log(country)
          if(!country) return;
          return country[0]?.name
        },
        renderEditCell: RenderDropdown
      },
      {
        key: 'origin',
        name: 'Origin',
        width: '120px',
        renderEditCell: RenderDropdown
      },
      {
        key: 'genres',
        name: 'Genre',
        width: '130px',
        resizable: true,
        className: 'multiDropCell',
        renderCell: ({row}) => {
          return row?.['genres']?.map(function(value) {
            return value.name +',';
          });
        },
      //  renderCell: MultiDropdown,
        renderEditCell: MultiDropdown
        
        // renderEditCell: (p, onRowChange) => (
        //   <select
        //     autoFocus
        //     value={p.row.genre}
        //     onChange={(e) => p.onRowChange({ ...p.row, genre: e.target.value }, true)}
        //   >
        //     {genre.map((gen) => (
        //       <option key={gen}>{gen}</option>
        //     ))}
        //   </select>)
      },
      {
        key: 'themes',
        name: 'Themes',
        width: '120px',
        className: 'multiDropCell',
        renderCell: ({row}) => {
          return row?.['themes']?.map(function(value) {
            return value.name +',';
          });
        },
        renderEditCell: MultiDropdown
      },
      {
        key: 'categories',
        name: 'categories',
        width: '140px',
        renderCell: ({row}) => {
          return row?.['categories']?.map(function(value) {
            return value.name +',';
          });
        },
        renderEditCell: MultiDropdown
      },
      {
        key: 'ratings',
        name: 'Rating',
        width: '120px',
        renderCell: ({row}) => {
          return row?.['ratings']?.[0]?.name || row?.['ratings'];
        },
        renderEditCell: RenderDropdown
      },
      {
        key: 'ratingsDescriptor',
        name: 'Ratings Descriptor',
        renderEditCell: textEditor
      },
      {
        key: 'territories',
        name: 'Territory',
        width: '120px',
        renderCell: ({row}) => {
          return row?.['territories']?.map(function(value) {
            return value.name +',';
          });
        },
        renderEditCell: MultiDropdown
      },
      {
        key: 'startDate',
        name: 'Start Date',
        width: '100px',
        renderCell({row}) {
          if(!row.startDate || row.startDate?.length<1) {
            return '';
          }
          const isDate = (new Date(row.startDate) !== "Invalid Date") && !isNaN(new Date(row.startDate));
          const Dates =  (isDate) ? new Date(row.startDate) : new Date();
          return dateFormatter.format(Dates);
        },
        renderEditCell: DatePickerGrid
      },
      {
        key: 'endDate',
        name: 'End Date',
        width: '100px',
        renderCell({row}) {
          if(!row.endDate || row.endDate?.length<1) {
            return '';
          }
          const isDate = (new Date(row.endDate) !== "Invalid Date") && !isNaN(new Date(row.endDate));
          const Dates =  (isDate) ? new Date(row.endDate) : new Date();
          return dateFormatter.format(Dates);
        },
        renderEditCell: DatePickerGrid
      },
      {
        key: 'audioLanguages',
        name: 'Audio Language',
        width: '120px',
        renderCell: ({row}) => {
          return row?.['audioLanguages']?.map(function(value) {
            return value.name +',';
          });
        },
        renderEditCell: MultiDropdown
      },
      {
        key: 'contentGroup',
        name: 'Content Group',
        width: '120px',
        renderEditCell: RenderDropdown
      }
      // ,
      // {
      //   key: 'country',
      //   name: 'Country',
      //   renderEditCell: (p) => (
      //     <select
      //       autoFocus
      //       value={p.row.country}
      //       onChange={(e) => p.onRowChange({ ...p.row, country: e.target.value }, true)}
      //     >
      //       {countries.map((country) => (
      //         <option key={country}>{country}</option>
      //       ))}
      //     </select>
      //   )
      // }
    ];
    }
    
  function handleFill({ columnKey, sourceRow, targetRow }){
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

    function EditableGrid() {
      const dispatch = useDispatch();
        const [rows, setRows] = useState([]);
        
        const [selectedRow, setSelectedRow] = useState([]);
        const navigate = useNavigate();
        const [locationData, setLocainData] = useState([])
        const locationState = useLocation();
        const [permission, setpermission] = useState(false);
        const getPermission = () => {
          let findEdit = permissionDetails().includes("ASSET_MANAGEMENT_EDIT");
          if(findEdit) setpermission(true)
             else setpermission(false);
        }
        useEffect(() => {
          getPermission();
          if(locationState?.state?.name) {
            setLocainData({state: locationState?.state});
          }
          
        }, [])
        const backMenu = () => {
          navigate(-1)
        }
        const { AudioLanguageList, MaturityList } = useSelector(state => ({ AudioLanguageList: state.AudioLanguageList?.data, MaturityList: state.MaturityList?.data }));
        const countries = [useMemo(() => {
            return [...new Set(rows.map((r) => r.country))].sort(new Intl.Collator().compare);
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])];
  const columns = useMemo(() => getColumns(countries, genre, 'ASC', AudioLanguageList), [AudioLanguageList]);

  function rowHeight(row) {
    
    return 104;
    // should be based on the content of the row
    if(row.shortSynopsis.length < 52) {
      return 50;
    }
    return Math.ceil(row.shortSynopsis.length / 52) * 22;
  }
  
  const getMetaData = async (partnerId = 1) => {    
    const URL = `${GET_METADATA}?partnerId=${partnerId}`;
    try {
      const response = await getInstantData(URL)
    //  response.data[0].genre = 'GN-DRA'
      setRows(response.data);
      dispatch(MetaGridAction(response.data));
     // return response.data;
    } catch (error) {
    }
    
  }

  const getId = (ar) => {
    if(!ar || !ar?.length ) {
      return [];
    }
    const id = ar.map((res) => res.id);
    return id;
  }

  const getName = (item) => {
    if(!item || !item?.length) return [];
    const name = MaturityList.filter((res) => (res.name === item) ? res.id : '')
    return [name?.[0]?.id]
  }
  const updateIds =  () => {
    if(!rows) {
      return;
    }
    const updatedData = rows.map(item => {
      const cloneItem = {...item}
      cloneItem.themeIds = getId(cloneItem.themes);
      cloneItem.categoryIds = getId(cloneItem.categories);
      cloneItem.genreIds = getId(cloneItem.genres);
      cloneItem.audioLanguageIds = getId(cloneItem.audioLanguages);
      cloneItem.ratingIds = getName(cloneItem.ratings);
      cloneItem.territoryIds = getId(cloneItem.territories);
      cloneItem.originalLanguageId = (cloneItem.originalLanguage) ? cloneItem.originalLanguage.id : []

      delete cloneItem.themes;
      delete cloneItem.genres;
      delete cloneItem.categories;
      delete cloneItem.audioLanguages;
      delete cloneItem.originalLanguage;
      delete cloneItem.ratings;
      delete cloneItem.territories;
      return cloneItem;
    });
    return updatedData;
  }
  const addMetaData = () => {
    const emptyRow =  {
      "createdAt": "",
      "updatedAt": "",
      "id": "",
      "programId": "",
      "title": "",
      "programType": "",
      "duration": "",
      "shortSynopsis": "",
      "actors": "",
      "directors": "",
      "releaseDate": "",
      "originalLanguage": "",
      "origin": "",
      "ratings": "",
      "ratingsDescriptor": "",
      "partnerId": locationData?.state?.id,
      "territories": [
      ],
      "startDate": "",
      "endDate": "",
      "themes": [
      ],
      "categories": [
      ],
      "genres": [],
      "audioLanguages": [],
      "contentGroup": "",
      "overridden": ""
  }
  const newRow = [emptyRow, ...rows];
  setRows(newRow)
  }

  const removeMetaData = async () => {
    if(!selectedRow?.id) {
      let removeEmpty = [...rows];
      removeEmpty.shift();
      setRows(removeEmpty);
      return;
    }
    const URL = Metadataupdate_URL+"/"+selectedRow.id;
    try {
      const response = await deleteInstantData(URL)
      toast.success("Metadata remove successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
   //   const deletedRow = rows.findIndex((data) => data.id === selectedRow.id)
      setRows(rows.filter((data) => data.id!==selectedRow.id));
      return response;
    } catch (error) {
      toast.error("Something went wrong, please try after sometime", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
    }
  }

  const emptyProgId = () => {
    const emptyProgramID = rows.find((data) =>{
      if(!data.programId) return data;
    });
    return (emptyProgramID) ? false : true;
  }

  const validateProgramID = () => {
    const duplicateProgramID = rows.find((data, index) =>{
      return rows.find((x, ind)=> x.programId === data.programId && index !== ind)
    });
    return (duplicateProgramID) ? false : true;
  }

  const updateMetaData = async () => {
    const URL = `${POST_METADATA_BULK}`;
    const emptyGrid = emptyProgId();
    if(!emptyGrid) {
      toast.error("Empty program ID not allowed", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      return;
    }
    const validateProgramIDval = validateProgramID();
    if(!validateProgramIDval) {
      toast.error("Duplicate program ID not allowed", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      return;
    }
    const updatedData = updateIds();
    setTimeout(async () => {
      const data = {
        "movies": updatedData
      }
      try {
        const response = await postInstantData(URL, data);
        
        setRows(response.data.data);
        dispatch(MetaGridAction(response.data.data));
        toast.success("Metadata updated successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      // setshowLoader(false)
      } catch (error) {
        toast.error("Something went wrong, please try after sometime", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
        });
      }
    }, 1000)

  }
  useEffect(() => {
    if(locationData?.state?.id) {
      getMetaData(locationData.state.id);
    }
  }, [locationData])

 
    return (
        <div className="content-body">
            <div className="dashboard-content">
                <div className="top-content">
                    <div className="left-side">
                        <h3><a onClick={backMenu} className={styles.backicon}><img src={backicon} alt="Back Icon" /></a> <span className={styles.backicontitle}>Metafile for {locationData?.state?.name}</span></h3>
                    </div>
                    {
                      permission?
                    <div className='right-side flex w-295'>
                      <Link onClick={addMetaData} className="default-odd border-0 btn-light-red-ml-auto add-row-btn">
                        {/* <img src={plusicon} alt="" /> */}
                        Add Row
                      </Link>
                      <Link onClick={updateMetaData} className="default-odd border-0 btn-light-red-ml-auto">
                        Update
                      </Link>
                      <Link onClick={removeMetaData} className="default-odd border-0 btn-light-red-ml-auto mr-0">
                        Delete
                      </Link>
                    </div>
                    :
                    ''
                    }
                </div>
                <div className={styles.bulkGrid}>
                  <DataGrid style={{height: window.innerHeight - 200 + 'px'}}  columns={columns} rows={rows} onRowsChange={setRows}
                    rowHeight={rowHeight}
                    getRowHeight={() => 'auto'}
                    onFill={handleFill}
                    // rowSelection={{
                    //   showCheckbox: true,
                    //   enableShiftSelect: true,
                    //  onRowsSelected: () => console.log('row'),
                    //   // onRowsDeselected: this.onRowsDeselected,
                    //   // selectBy: {
                    //   //   indexes: this.state.selectedIndexes
                    //   // }
                    // }}
                    onCellClick={(args, event) => {
                      if (args.column.key === 'genres' || args.column.key === 'rating' || args.column.key === 'categories' || args.column.key === 'themes' || args.column.key === 'audioLanguages' || args.column.key === 'ratings' || args.column.key === 'originalLanguage' ) {
                        event.preventGridDefault();
                       // args.selectCell(true);
                      }
                      else if (args.column.key === 'startDate' || args.column.key === 'endDate' || args.column.key === 'releaseDate') {
                        event.preventGridDefault();
                        args.selectCell(true);
                      }
                      else {
                        args.selectCell(true);
                      //  if(args.column.key !== 'programId') {
                        setTimeout(() => {
                          event.target.click();
                        })
                     // }
                      }
                      setSelectedRow(args.row);
                    }}
                  />
                  </div>
            </div>
            <ToastContainer />
        </div>
    )
} 

export default EditableGrid;