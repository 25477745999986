import React, { useState } from "react";
import uploadicon from "../../assets/images/uploadicon.png";
import deleteicon from "../../assets/images/delete_forever.png";
import archiveicon from "../../assets/images/archiveicon.png";
import Groupicon from "../../assets/images/Group.png";
import option from "../../assets/images/option.png";



function VideoBlock({onUploadPromos, data, onVideoClick, onArchiveClick, onUploadPosterClick,  onDeleteClick,  image_url,assetSignledata ,openplayercomp, permission}) {
 
  let selectedImageUrl = '';

  const posters = data?.posters || [];

  // Check for Mobile -> Hero
  const mobileHero = posters.find(
    poster => poster.deviceType === 'MOBILE' && poster.coverImagePath != null
  );

  if (mobileHero) {
    selectedImageUrl = image_url + mobileHero.coverImagePath;
  } else {
    // If Mobile -> Hero not available, find any other Hero
    const otherHero = posters.find(poster => poster.coverImagePath != null);

    if (otherHero) {
      selectedImageUrl = image_url + otherHero.coverImagePath;
    } else {
      // If no Hero, check for Mobile -> Cover
      const mobileCover = posters.find(
        poster => poster.deviceType === 'MOBILE' && poster?.heroImagePath != null
      );

      if (mobileCover) {
        selectedImageUrl = image_url + mobileCover.heroImagePath;
      } else {
        // If no Mobile -> Cover, select any other Cover
        const otherCover = posters.find(poster => poster?.heroImagePath != null);

        if (otherCover) {
          selectedImageUrl = image_url + otherCover.heroImagePath;
        }
        // If no Mobile -> Cover or any other Cover found, selectedImageUrl remains empty
      }
    }
  }
 let clickTimeout;

  const handleDoubleClick = (data) => {
    // Add your double-click functionality here
    // For example:
    openplayercomp(data)
};

function handleClick(data, e) {
    if (!clickTimeout) {
      // This executes on single click
  
      clickTimeout = setTimeout(() => {
        clickTimeout = null;
        onVideoClick(data, false, e);
      }, 200); // Adjust the delay as needed
    } else {
      // This executes on double click
      clearTimeout(clickTimeout);
      clickTimeout = null;
      handleDoubleClick(data);
    }
  }



  return (
    <div className={`block ${data?.id == assetSignledata?.id? "active" : ""}`} onClick={  (e)=>handleClick(data, e)}>
      {data.posters?.length == 0 ||  selectedImageUrl == ""? (
      <div className="img-block" style={{ background: data?.isPlaceHolder ? "rgb(246 246 205)" : "" }}>
          <img src={Groupicon} alt="" />
        </div>
      ) : (
        <div className="img-block p-0" style={{ overflow: "hidden"}}>
          <img
            style={{ width: "100%", height: "auto" }}
            src={ selectedImageUrl }
            alt=""
          />
        </div>
      )}
      <div className="text-block">
        <label>
          {data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename :data?.metadata?.title}

        </label>
      </div>
      {
      permission === "edit"?
      <div className="dropdown">
        <a className="dropdown-button">
          <img src={option} />
        </a>
        <div className="dropdown-content">
          <a
            className="delete-button"
            onClick={(e) => onUploadPosterClick(data, true, e)}
          >
            <span className="img-box">
              <img src={uploadicon} />
            </span>
            Upload Poster
          </a>
        
        <a
          className="delete-button"
          onClick={() => document.getElementById("fileInputs").click()}
        >
          <input
          type="file"
          id="fileInputs"
          style={{ display: "none" }}
          accept=".mp4,.mov"
          multiple
          onChange={(e) => onUploadPromos(e, data.id)}
        />
            <span className="img-box">
              <img src={uploadicon} />
            </span>
        Upload Promo 
          </a>
          <a className="delete-button"
            onClick={(e) => onArchiveClick( e)}
          >
            <span className="img-box">
              <img src={archiveicon} />
            </span>
            Archive
          </a>
          <a className="delete-button" onClick={(e) => onDeleteClick(data.id, e)}>
            <span className="img-box">
              <img src={deleteicon} />
            </span>
            Delete
          </a>
        </div>
      </div>
      :
      ''
      }
    </div>
  );
}

export default VideoBlock;
 