import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import rightarrow from "../../assets/images/rightvector.png";
import leftarrow from "../../assets/images/Vector.png";
import icroundsearch from "../../assets/images/ic_round-search.svg";
import sortingUp from '../../assets/images/sortarrow.png';
import sortingDown from '../../assets/images/desc.png';
import sortingDefault from '../../assets/images/sorting-default.svg';
import { Dropdown } from "react-bootstrap";

const AuditLogs = () => {
    // <----------------- React hooks and variables ------------------>

    const [auditLogList, setAuditLogList] = useState([]);
    const [isDropdownOpenArray, setIsDropdownOpenArray] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const {
        appThemeReducer: { appTheme },
      } = useSelector((state) => state);
    const itemsPerPage = 10;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = auditLogList?.slice(startIndex, endIndex);
    const totalPages = Math.ceil(auditLogList.length / itemsPerPage);

    // <----------------- End of react hooks and variables ------------------>


    // <----------------- Functions performed on this page ------------------>

    // Function to set the pagination of datatable
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    // Function to get Audit logs all data
    const getAuditLogs = async() => {
        return;
    }

    useEffect(() => {
        getAuditLogs();
    }, []);

    // Function to search and retrieve data
    const auditLogsSearch = async (searchQuery) => {
        // const auditLogsDataSearch = await Audit_logs_search(searchQuery);
        // if (auditLogsDataSearch?.data && auditLogsDataSearch?.status == true) {
        //     setAuditLogList(auditLogsDataSearch?.data);
        // }
    };
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            auditLogsSearch(searchQuery);
        }
    };

    // Function to filter and sort data
    const handleSorting = (column) => {
        let sorting = {
          sortColumn: column,
          sortDirection: null
        }
        setSortColumn(column);
        if (sortDirection == 'asc') {
          setSortDirection('desc');
          sorting.sortDirection = 'desc';
        } else {
          setSortDirection('asc');
          sorting.sortDirection = 'asc';
        }
    
        let queryParams = {
          sortBy: sorting.sortColumn,
          sortOrder: sorting.sortDirection,
          pageNumber: currentPage,
          pageSize: pageSize
        };
        getAuditLogs(queryParams);
    }

    // Function to handle action dropdown button
    const handleDropdownSelect = (selectedOption, index) => {
        setIsDropdownOpenArray((prevState) =>
          prevState.map((value, i) => (i === index ? false : value))
        );
    };

    // <----------------- End of functions performed on this page ------------------>


    // <----------------- JSX ------------------>
    return (
        <div className="content-body">
            <div className="dashboard-content">

                {/* Top content (heading and search bar) */}
                <div className="top-content">

                    {/* Heading */}
                    <div className="left-side">
                        <h3 className="mb-0">Audit Logs</h3>
                    </div>
                    {/* End of Heading */}

                    {/* Search Bar */}
                    <div className="right-side">
                        <div className="icon-list">
                            <div className={`search-box bg-white p-1 mr-16 ${appTheme}`}>
                                <img src={icroundsearch} alt="Search" />
                                <input
                                    className="border-0"
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                        </div>
                    </div>
                    {/* End of Search Bar */}

                </div>
                {/* End of top content (heading and search bar) */}

                {/* Page Content */}
                <div className='channel-setup-table'>
                    <div className="cms-table-list tenant-table">

                        {/* Table Body */}
                        <table className='w-100'>

                            {/* Table Headings */}
                            <thead>
                                <tr>
                                    <th onClick={() => { handleSorting('timeStamp') }} >Time
                                        <span>
                                            {sortColumn == 'timeStamp' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'timeStamp' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'timeStamp' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th onClick={() => { handleSorting('userName') }}>User
                                        <span>
                                            {sortColumn == 'userName' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'userName' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'userName' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th onClick={() => { handleSorting('email') }}>Email
                                        <span>
                                            {sortColumn == 'email' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'email' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'email' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th onClick={() => { handleSorting('contentType') }}>Content Type
                                        <span>
                                            {sortColumn == 'contentType' && sortDirection == 'asc' ? <img src={sortingUp} /> : null}
                                            {sortColumn == 'contentType' && sortDirection == 'desc' ? <img src={sortingDown} /> : null}
                                            {sortColumn != 'contentType' ? <img src={sortingDefault} /> : null}
                                        </span>
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            {/* End of Table Heading */}

                            {/* Table Data */}
                            <tbody>
                                <tr>
                                    <td>12:30PM</td>
                                    <td>Chota Pawan</td>
                                    <td>chotu@420.com</td>
                                    <td>xyz</td>
                                    <td>
                                        Actions
                                        {/* <Dropdown
                                            show={isDropdownOpenArray[index]}
                                            onToggle={(event) => {
                                                setIsDropdownOpenArray((prevState) =>
                                                prevState.map((value, i) =>
                                                    i === index ? !value : false
                                                )
                                                );
                                            }}
                                            >
                                            <Dropdown.Toggle
                                                id={`dropdown-basic-${index}`}
                                                variant="light"
                                                style={{ cursor: "pointer" }}
                                                className="three-dots"
                                            >
                                                <span className="px-4">⋮</span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openEditTenant(user);
                                                }}
                                                eventKey="option1"
                                                onSelect={(e) =>
                                                    handleDropdownSelect("option1", index)
                                                }
                                                >
                                                <img src={viewIcon} alt="" /> View
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    openEditTenant(user);
                                                }}
                                                eventKey="option1"
                                                onSelect={(e) =>
                                                    handleDropdownSelect("option1", index)
                                                }
                                                >
                                                <img src={editicon} alt="" /> Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                eventKey="option3"
                                                onSelect={() =>
                                                    handleDropdownSelect("option3", index)
                                                }
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    deleteTenant(user);
                                                }}
                                                >
                                                <img src={deleteicon} alt="" /> Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                    </td>
                                </tr>
                            </tbody>
                            {/* End of Table Data */}

                        </table>
                        {/* End of Table Body */}
                        
                        {/* Pagination */}
                        <div className="table-pagination">
                            <div className="pagination-count">
                                <div className="count">
                                    {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
                                        currentPage * itemsPerPage,
                                        auditLogList.length
                                    )} of ${auditLogList.length}`}
                                </div>
                                <div className="pagination-arrow">
                                    <a
                                        className="prev"
                                        onClick={() =>
                                          currentPage > 1 &&
                                          handlePageChange(currentPage - 1)
                                        }
                                    >
                                        <img src={leftarrow} alt="Previous" />
                                    </a>
                                    <a
                                        className="next"
                                        onClick={() =>
                                          currentPage < totalPages &&
                                          handlePageChange(currentPage + 1)
                                        }
                                    >
                                        <img src={rightarrow} alt="Next" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* End of Pagination */}

                    </div>
                </div>
                {/* End of Page Content */}

                {/* Toast Alert */}
                <ToastContainer />
                {/* End of Toast Alert */}

            </div>
        </div>
    )
    // <----------------- End of JSX ------------------>
}

export default AuditLogs;