import React, { useEffect, useState } from "react";
import "./vieweship.scss";
import ActiveUser from "./active-user";
import AccordionBlock from "./accordian/AccordionBlock";
import AccordionFilter from "./accordian/AccordionFilter";
import { useDispatch } from "react-redux";
import { ChannelDataAction, MapDataAction } from "../../Redux/slices";
import { ViewershipMap, ViewershipTableChild, ViewershipTablehead } from "../../api/api";
import { TimeConverter, capitalizeFirstLetter, formatNumber, isLogin } from "../../service/commonFunc";

import sort from "../../assets/images/sort.png";
import desc from "../../assets/images/desc.png";
import filter_alt from "../../assets/images/sortarrow.png";
import { useSelector } from "react-redux";
import { Line } from 'react-chartjs-2';
import { Doughnut } from "react-chartjs-2";
import 'chart.js/auto';

function Viewership() {
  useEffect(() => {
    isLogin()
  }, [])
  const [openIndex, setOpenIndex] = useState(null);
  const [showLoader, setshowLoader] = useState(true);
  const [Duration, setDuration] = useState("Last 24 Hours");
  const [TableHead, setTableHead] = useState([]);
  const [filterVal, setFilterVal] = useState({
    viewType: "CHANNEL",
    duration: "DAY",
  });
  const {
    appThemeReducer: { appTheme },
    adminModeReducer: { adminMode },
  } = useSelector((state) => state);
  const [xAxis, setXAxis] = useState([]);
  const [yAxis, setYAxis] = useState([]);
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({
    plugins: {
      tooltip: {
        enabled: true, // Enable tooltips
        mode: 'index',
        intersect: false,
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          }
        }
      },
      legend: {
        display: true,
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Program Name'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Information'
        }
      }
    }
  });
  const [sortOrders, setSortOrders] = useState({["viewKey"]: "asc"}); // Store sorting orders for each column
  // const [headeFilter, setheadeFilter] = useState({ sort: "asc", key: "viewKey" });
  const [graphicalView, setGraphicalView] = useState(false);
  const [TotalHeader, setTotalHeader] = useState({
    "viewType": "HEADER",
    "viewKey": "Average",
    "numberOfActiveUsers": 0,
    "totalWatchHours": 0,
    "averageWatchTimePerUser": 0,
    "averageWatchTimePerSession": 0,
    "totalAdImpression": 0
  });
  const changeGraphView = (val) => {
    const xdata = [];
    const ydata = [];
    const ydata2 = [];
    const ydata3 = [];
    const ydata4 = [];
    const ydata5 = [];
    TableHead.map((data) => {
      xdata.push(data.viewKey);
      ydata.push(data.numberOfActiveUsers);
      ydata2.push(data.totalWatchHours);
      // ydata3.push(data.averageWatchTimePerSession);
      // ydata4.push(data.averageWatchTimePerUser);
      ydata3.push(data.totalAdImpression);
    });
    setXAxis(xdata);
    setYAxis(ydata);
    setChartData({
      labels: xdata,
      datasets: [
        {
          label: 'Number of Active Users',
          data: ydata,
          fill: false,
          backgroundColor: 'rgb(0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0.7)',
        }, {
          label: 'Total watch hours',
          data: ydata2,
          fill: false,
          backgroundColor: 'rgb(0, 50, 200)',
          borderColor: 'rgba(0, 50, 200, 0.7)',
        },
        {
          label: 'Total ad impression',
          data: ydata3,
          fill: false,
          backgroundColor: 'rgb(255, 255, 0)',
          borderColor: 'rgba(255, 255, 0, 0.7)',
        },
      ],
      options: {
        scales: {
          y: {
            beginAtZero: true, // This ensures the y-axis starts at 0
            ticks: {
              precision: 0 // This can be used to avoid decimal values on the y-axis
            }
          }
        }
      }
    })
    setGraphicalView(val);
  }
  // Store sorting orders for each column
  // Function to toggle the visibility of the inner content
  const toggleAccordion = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the currently open block if clicked again
    } else {
      setOpenIndex(index); // Open the clicked block
    }
    // if (openIndex === index) {

    //   filterChangeData(filterVal.viewType, filterVal.duration, { sort: "asc", key: "viewKey" })
    // }
  };
  const allAccordionBlocksClosed = openIndex === null;

  // const accordionData = Tablejson?.TableHead

  const dispatch = useDispatch();
  const sortOrderEvent = (columnValue) => {
   
    const currentSortOrder = sortOrders[columnValue] || "viewKey"; // Default to "desc" if not set
    const newSortOrder = currentSortOrder === "desc" ? "asc" : "desc";
  
   
    // Set the sorting order for the clicked column
    

    // Update the sorting order for the clicked column
    setSortOrders({  [columnValue]: newSortOrder });
    const headeFilter = {
      sort: newSortOrder,
      key: columnValue,
    }


    filterChangeData(filterVal.viewType, filterVal.duration, headeFilter)

  };

  //on page laod show Total channel data




  const mapRenderData = async (key) => {

    const ViewershipMapData = {

      channelName: key,
      allChannels: true

    };

    const MapData = await ViewershipMap(ViewershipMapData);

    if (MapData?.data) {
      // console.log("mapRenderData",MapData);
      dispatch(MapDataAction(MapData?.data));
    }
  };

// console.log("sortOrders",sortOrders);

  const defaultSortColumn = "showName"; // Default sorting column




  const filterChangeData = async (view, duration, headeFilter) => {
    // console.log("headeFilter", headeFilter);
    const ViewershipTableheadData = {
      viewType: view.toUpperCase(),
      duration: duration.toUpperCase(),
      sortOrder: headeFilter.sort,
      sortBy: headeFilter.key
    };

    const data = await ViewershipTablehead(ViewershipTableheadData, setshowLoader);
    if (duration == "Month") {

      setDuration("Last 30 Days")
    } else if (duration == "Week") {

      setDuration("Last 7 Days")

    }
    else if (duration == "Year") {

      setDuration("Last 365 Days")

    } else {

      setDuration("Last 24 Hours")
    }

    setTableHead(data?.data.data.rows);
    mapRenderData(data?.data.data?.header.viewKey)
    setTotalHeader(data?.data.data?.header)
    // console.log("filte",  filterVal?.duration,  duration.toUpperCase());
    if (filterVal.duration == duration.toUpperCase()) {
      setOpenIndex(null);
      dispatch(ChannelDataAction(data?.data.data?.header));


    }
    let filterObj = {
      viewType: view.toUpperCase(),
      duration: duration.toUpperCase(),
    };
    setFilterVal(filterObj);

  };


  return (
    <>
    <div className={`content-body ${(adminMode || appTheme == "dark") ? "dark" : "light"}`}>
      {/* <DashboardHeader /> */}
      <div className={`deshboard-content ${(adminMode || appTheme == "dark") ? "dark" : "light"}`}>
        <div className="title">
          <h2>Viewership </h2>
        </div>
        <AccordionFilter  setSortOrders={setSortOrders} Durations={Duration} handleChange={filterChangeData} handleGraphicalView={changeGraphView}/>
        <div className="Duration-time">
          {/* <p>Date Range:  {formatDate(startDate)} - {formatDate(endDate)}</p> */}

        </div>
        <div className="channel-container">
          <div className="channel-table">
            <div className="channel-table-header">
              <div className="table-row  head" >
                <div className="table-header-col name" >
                  {capitalizeFirstLetter(filterVal.viewType)} Name


                  <a className='filter-icon mx-2' onClick={() => sortOrderEvent("viewKey")}>
                    {sortOrders["viewKey"] === "desc" && <img src={desc} alt="Descending" />}
                    {sortOrders["viewKey"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {!sortOrders["viewKey"] && <img src={sort} alt="No Sorting" />}
                  
                  </a>


                </div>
                <div className="table-header-col">Active Users
                  <a className='filter-icon mx-2' onClick={() => sortOrderEvent("numberOfActiveUsers")}>

                  {sortOrders["numberOfActiveUsers"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["numberOfActiveUsers"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["numberOfActiveUsers"] && <img src={sort} alt="No Sorting" />}
                  
                  </a>

                </div>
                <div className="table-header-col">Total Watch Hours
                  <a className='filter-icon mx-2' onClick={() => sortOrderEvent("totalWatchHours")}>
                  {sortOrders["totalWatchHours"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["totalWatchHours"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["totalWatchHours"] && <img src={sort} alt="No Sorting" />}
                  
                  </a>
                </div>
                <div className="table-header-col">
                  Avg - Watch Time<a className='filter-icon mx-2' onClick={() => sortOrderEvent("averageWatchTimePerUser")}>
                  {sortOrders["averageWatchTimePerUser"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["averageWatchTimePerUser"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["averageWatchTimePerUser"] && <img src={sort} alt="No Sorting" />}
                  
                  </a> Per User
                  <br />
                  <small>(HH:MM:SS)</small>

                </div>
                <div className="table-header-col" onClick={() => sortOrderEvent("averageWatchTimePerSession")}>
                  Avg - Watch Time <a className='filter-icon mx-2' onClick={() => sortOrderEvent("averageWatchTimePerSession")}>
                  {sortOrders["averageWatchTimePerSession"] === "asc" && <img src={filter_alt} alt="Ascending" />}
                    {sortOrders["averageWatchTimePerSession"] === "desc" && <img src={desc} alt="Descending" />}
                    {!sortOrders["averageWatchTimePerSession"] && <img src={sort} alt="No Sorting" />}
                  
                  </a> Per Session  <br />
                  <small>(HH:MM:SS)</small>

                </div>
                {/* <div className="table-header-col" onClick={() => sortOrderEvent("totalAdImpression")}>Total Ad Impression
                  <a className='filter-icon mx-2' onClick={() => sortOrderEvent("totalAdImpression")}>
                    <img src={filter_alt} alt="Filter" />
                  </a>
                </div> */}
              </div>
            </div>
            {/* { showLoader &&<Loader />   }     */}
            <div
              onClick={() => filterChangeData(filterVal.viewType, filterVal.duration, { sort: "desc", key: "viewKey" })}
              className="channel-table-header"
            >
              <div className="table-row" style={{cursor:"pointer"}}>
                <div className="table-header-col name">
                  All {capitalizeFirstLetter(filterVal.viewType)}s
                </div>
                <div className="table-header-col">{formatNumber(TotalHeader?.numberOfActiveUsers)}</div>
                <div className="table-header-col">{formatNumber(TotalHeader?.totalWatchHours?.toFixed(2))} Hrs</div>
                <div className="table-header-col">
                  {TimeConverter(TotalHeader?.averageWatchTimePerUser)}
                  <br />

                </div>
                <div className="table-header-col">
                  {TimeConverter(TotalHeader?.averageWatchTimePerSession)}

                </div>
                {/* <div className="table-header-col">{TotalHeader?.totalAdImpression}</div> */}
              </div>
            </div><div className="channel-table-body">
              {
                graphicalView === false?
                <div className="channel-accordion">
                  {TableHead &&
                    TableHead?.map((data, index) => (
                      <AccordionBlock
                        key={index}
                        title={data}
                        closeaccodian={() => filterChangeData(filterVal.viewType, filterVal.duration)}
                        isOpen={index === openIndex}
                        onToggle={() => toggleAccordion(index)}
                        filter={filterVal} 
                        // graphView={graphicalView} 
                        />
                    ))}
                </div>
                :
                <Line data={chartData} options={chartOptions} />
              }
            </div>
          </div>
          <div className="active-user-section">
            <ActiveUser />
            {/* <div style={{position:"absolute", top:"20%"}}>
            <MyCalendar />
    </div> */}

          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Viewership;
