import React, { useState, useEffect, useRef } from "react";
import "./style.scss";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {
  Categories_list,
  Country_list,
  Genres_list,
  Language_list,
  MetadataAdd,
  Metadataupdate,
  Partner_list,
  Theme_list,
} from "../../api/api";
import { ContentGroup, image_url } from "../../service/Constant";
import check from "../../assets/images/enabled-featured.png";
import uncheck from "../../assets/images/disabled-featured.png";
import DynamicMultiSelect, {
  CustomMultiSelect,
} from "../multiselect/MultiSelect";
import { useSelector } from "react-redux";
import { Permission } from "@aws-sdk/client-s3";
import {xmlData} from "../../utility/xmlData";

function Partitioncontent({
  videodata,
  closepartition,
  assetdetail,
  expandMetaView,
  permission,
}) {
    
  // Define state variables for input values
  // const metadata = videodata.metadata;
  const AssetPartnerDetail = JSON.parse(
    localStorage.getItem("AssetPartnerDetail")
  );
  const [metadata, setMetadata] = useState(videodata?.metadata);
  const state = useSelector((state) => state);
  const genre = state?.GenreList?.data;
  const language = state?.AudioLanguageList?.data;
  const Themes = state?.ThemesList?.data;
  const Category = state?.CategoriesList?.data;
  const Ratings = state?.MaturityList?.data;
  const Territorys = state?.CountryList?.data?.data;

  // Field Options State
  const [partnerOption, setPartnerOptions] = useState([]);
  const [territoryOption, setTerritoryOption] = useState([]);
  const [categoryOptions, setCategoryOption] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [themeList, setThemeList] = useState([]);
  const [ratingList, setRatingList] = useState([]);
  // const [fillerList, setFillerList] = useState([]);
  const [gernreList, setGenreList] = useState([]);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([]);
  const [selectedThemeIds, setSelectedThemeIds] = useState([]);
  const [selectedLanguageIds, setSelectedLanguageIds] = useState([]);
  const [selectedGenreIds, setSelectedGenreIds] = useState([]);
  const [selectedTerritoryIds, setSelectedTerritoryIds] = useState([]);

  const [updatebtn, setupdatebtn] = useState(false);
  const [featurecheck, setFeaturecheck] = useState(false);
  const [partnerCode, setPartnerCode] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [programId, setProgramId] = useState("");
  const [title, setTitle] = useState("");
  const [programType, setProgramType] = useState("");
  const [duration, setDuration] = useState("");
  const [shortSynopsis, setShortSynopsis] = useState("");
  const [actors, setActors] = useState("");
  const [directors, setDirectors] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [originalLanguage, setOriginalLanguage] = useState("");
  const [origin, setOrigin] = useState("");
  const [ratingvalue, setRating] = useState("");
  const [ratingsDescriptor, setRatingsDescriptor] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [contentGroup, setContentGroup] = useState("");


  useEffect(() => {
    if (videodata?.metadata != null) {
      setEditable(true);
      const newMetadata = videodata?.metadata;

      setMetadata(newMetadata);
      setFeaturecheck(newMetadata?.featured == null ? false : newMetadata?.featured)
      setPartnerCode(newMetadata?.partnerCode);
      setPartnerName(newMetadata?.partnerName);
      // setSourceLocation(newMetadata?.sourceLocation);
      setProgramId(newMetadata?.programId);
      // setVodSource(newMetadata?.vodSource);
      setTitle(newMetadata?.title);
      setProgramType(newMetadata?.programType);
      setDuration(newMetadata?.duration);
      setShortSynopsis(newMetadata?.shortSynopsis);
      setActors(newMetadata?.actors);
      setDirectors(newMetadata?.directors);

      setOriginalLanguage(newMetadata?.originalLanguage);
      setOrigin(newMetadata?.origin);
      let rate = newMetadata?.ratings[0]?.id
      setRating(rate);
      setRatingsDescriptor(newMetadata?.ratingsDescriptor);
      // setTerritory(newMetadata?.territory);
      const end = parseInt(newMetadata?.endDate, 10); // Convert string to number
      const timestamp = parseInt(newMetadata?.startDate, 10); // Convert string to number
      const startdate = !isNaN(timestamp) ? new Date(timestamp) : null;
      const enddate = !isNaN(end) ? new Date(end) : null;


      const redate = parseInt(newMetadata?.releaseDate, 10);
      const daterelease = !isNaN(redate) ? new Date(redate) : null;
      setReleaseDate(daterelease);

      setStartDate(startdate);
      setEndDate(enddate);
      // setCoverartFilename(newMetadata?.coverartFilename);
      // setHeroartFilename(newMetadata?.heroartFilename);
      // setAudioLanguages(newMetadata?.audioLanguages);

      setContentGroup(newMetadata?.contentGroup);

      setSelectedThemeIds(newMetadata?.themes.map((obj) => obj.id));
      setSelectedCategoryIds(newMetadata?.categories?.map((obj) => obj.id));
      setSelectedLanguageIds(newMetadata?.audioLanguages?.map((obj) => obj.id));
      setSelectedGenreIds(newMetadata?.genres?.map((obj) => obj.id));
      setSelectedTerritoryIds(newMetadata?.territories?.map(obj => obj.id))
    } else {

      setMetadata("");
      setPartnerCode("");
      setPartnerName("");
      // setSourceLocation(newMetadata?.sourceLocation);
      setProgramId("");
      // setVodSource(newMetadata?.vodSource);
      setTitle("");
      setProgramType("");
      setDuration("");
      setShortSynopsis("");
      setActors("");
      setDirectors("");
      setFeaturecheck(null)
      setOriginalLanguage("");
      setOrigin("");
      setRating();
      setRatingsDescriptor("");
      // setTerritory([]);
      // const end = parseInt(newMetadata?.endDate, 10); // Convert string to number
      // const timestamp = parseInt(newMetadata?.startDate, 10); // Convert string to number
      // const startdate = !isNaN(timestamp) ? new Date(timestamp) : null;
      // const enddate = !isNaN(end) ? new Date(end) : null;


      setReleaseDate(null);
      setStartDate(null);
      setEndDate(null);
      // setCoverartFilename(newMetadata?.coverartFilename);
      // setHeroartFilename(newMetadata?.heroartFilename);
      // setAudioLanguages(newMetadata?.audioLanguages);

      setContentGroup();

      setSelectedThemeIds([]);
      setSelectedCategoryIds([]);
      setSelectedLanguageIds([]);
      setSelectedGenreIds([]);
      setSelectedTerritoryIds([])
    }
  }, [videodata]);



  const handleDoneClick = () => {
    setupdatebtn(true)
    // Collect all the data
    // const data = {
    //   title,
    //   programType,
    //   duration,
    //   shortSynopsis,
    // };
   
    updatemetadata(videodata?.metadata?.id);
  };
  const featuredchecks = () => {
    if(editable == false){
      if(featurecheck){
        setFeaturecheck(false)
      }else{
        setFeaturecheck(true)
      }
    }
  
   
    // Collect all the data
    
  };

  const updatemetadata = async (id, objdata) => {
    let data = {
      ...(videodata?.metadata == null && { programId: videodata?.filename }),
      ...(videodata?.metadata == null && {
        partnerId: AssetPartnerDetail?.id
      }),
      title: title,
      programType: programType,
      duration: duration,
      shortSynopsis: shortSynopsis,
      actors: actors,
      directors: directors,
      releaseDate: releaseDate == null ? "" : new Date(releaseDate).getTime(),
      originalLanguageId: Number(originalLanguage),
      origin: origin,
      ratingIds: ratingvalue == null ? [] : [ratingvalue],
      ratingsDescriptor: ratingsDescriptor,
      territoryIds: selectedTerritoryIds,
      startDate: startDate == null ? "" : new Date(startDate).getTime(),
      endDate: endDate == null ? "" : new Date(endDate).getTime(),
      audioLanguageIds: selectedLanguageIds,
      themeIds: selectedThemeIds,
      categoryIds: selectedCategoryIds,
      genreIds: selectedGenreIds,
      contentGroup: contentGroup,
      featured: featurecheck,
    };


    
    // return;
    if (title != "") {
      if (videodata?.metadata != null) {
        const metadata = await Metadataupdate(id, data);
        if (metadata?.data.status == true) {

          // dispatch(AssetDetailAction(AssetDetail?.data))

          setEditable(true);
          toast.success("Metadata updated successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        } else {
          toast.error("Something went wrong, please try after sometime", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });
        }
      } else {
        const addmetadata = await MetadataAdd(data);
        if (addmetadata?.data.status == true) {

          // dispatch(AssetDetailAction(AssetDetail?.data))

          toast.success("Metadata added successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });

        } else {
          toast.error("Something went wrong, please try after sometime", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 5000,
          });

        }
      }
      setupdatebtn(false)
      assetdetail();
    } else {
      toast.error("Title is required", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
      });
      setupdatebtn(false)
    }
  };


  useEffect(() => {
    // getChannelThemeList();
    // getChannelCategoryList();
    // getLanguageList()
    getPartnerList()
    // getgenreList()
    // getCountriesList()
    setTerritoryOption(Territorys)
    setGenreList(genre)
    setLanguageList(language)
    setCategoryOption(Category)
    setThemeList(Themes)
    setRatingList(Ratings)
  }, [])

  // Get Terretory Option List
  // const getCountriesList = async () => {
  //   const countriesData = await Country_list();

  //   let countries = countriesData.map((item) => {
  //     return { territoryId: item.flag, niceName: item.name.common };
  //   });

  //   countries.sort((a, b) => {
  //     return a.niceName.localeCompare(b.niceName);
  //   });

  //   if (countries && countries?.length) {
  //     setTerritoryOption(countries);
  //   }
  // };

  // Get Partner Option List
  const getPartnerList = async () => {
    const partnerData = await Partner_list();
    if (partnerData?.data && partnerData?.status === true) {
      setPartnerOptions(partnerData.data);
    }
  };

  const getgenreList = async () => {
    const languageList = await Genres_list({
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
    });
    if (languageList?.content && languageList?.content?.length) {
      setGenreList(languageList.content);
    }
  };

  // Get Language List
  const getLanguageList = async () => {
    const languageList = await Language_list();
    if (languageList?.content && languageList?.content?.length) {
      setLanguageList(languageList.content);
    }
  };

  // Get Channel Category List
  const getChannelCategoryList = async () => {
    const categoryList = await Categories_list({
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
    });
    if (categoryList?.content && categoryList?.content?.length) {
      setCategoryOption(categoryList.content);
    }
  };

  // Get Theme List
  const getChannelThemeList = async () => {
    const themeList = await Theme_list({
      searchText: "",
      sortBy: "name",
      sortOrder: "asc",
    });
    if (themeList?.content && themeList?.content?.length) {
      setThemeList(themeList.content);
    }
  };

  // Get Filler List
  //   const getFillerByPartner = async(id) => {
  //     const fillerList = await FillerList(id);
  //     if (fillerList?.data && fillerList?.data?.length) {
  //       setFillerList(fillerList.data);
  //     }
  //   }

  const [editable, setEditable] = useState(true);

  // Validation function for the title field
  const validateTitle = (value) => {
    if (value.trim() === "") {
      return "Title is required";
    } else {
      return "";
    }
  };
  const handleButtonClick = () => {
    if (editable) {
      setEditable(false);
    } else {
      handleDoneClick();
    }
  };

  return (
    <div className="col-4 right-content">
      <div className="video-container">
        <div className="video-title">
          {videodata?.metadata == null
            ? videodata?.filename
            : videodata?.metadata?.title}
          <p className="small-grey-text">{videodata?.metadata == null
            ? null
            : "(" + videodata?.filename + ")"}</p>

        </div>

        { permission === "edit"? featurecheck  ? <button className="image-container"  style={{ marginLeft: "auto" }} onClick={() => featuredchecks()}>
          <img
            src={check}
            className="image"
          />
        </button>:
        <button className="image-container"   style={{ marginLeft: "auto" }} onClick={() => featuredchecks()}>
          <img
            src={uncheck}
            className="image"
          />
        </button>:''}
        {
          permission === "edit"?
          <button
            className="image-container"
            style={{ marginLeft: "auto" }}
            onClick={() => expandMetaView()}
          >
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ef96ee80-845a-42e8-b7f5-1f3ece190eaa?"
              className="image"
            />
          </button>
          :
          ''
        }
        
        <button className="image-container" onClick={() => closepartition()}>
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ce38f7a-447d-4033-ab0f-087a81ad20f5?"
            className="image"
          />
        </button>
    
      </div>
      <div className=" part-height my-4 ">
        <div className="video-details ">
            {
              xmlData.map((data, index) => (
                <div key={index}>
                  {data?.fields.map((data2, index2) => {
                    if (data2?.type === "text" || data2?.type === "email" || data2?.type === "password" || data2.type === "color" || data2?.type === "file" || data2?.type === "checkbox" || data2?.type === "search") {
                      if (data2?.name === "title") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Title<span style={{ color: "red" }}>*</span></div>
                            <input
                              className="detail-inputs"
                              readOnly={editable}
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "programType") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Program Type</div>
                            <input
                              className="detail-inputs"
                              readOnly={editable}
                              value={programType}
                              onChange={(e) => setProgramType(e.target.value)}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "duration") {
                        return (  
                          <div className="detail-row ">
                            <div className="detail-label">Duration</div>
                            <input
                              className="detail-inputs"
                              readOnly={editable}
                              value={duration}
                              onChange={(e) => setDuration(e.target.value)}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "ratingsDescriptor") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Ratings Descriptor</div>
                            <input
                              className="detail-inputs"
                              readOnly={editable}
                              value={ratingsDescriptor}
                              onChange={(e) => setRatingsDescriptor(e.target.value)}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "actors") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Actors</div>
                            <input
                              className="detail-inputs"
                              readOnly={editable}
                              value={actors}
                              onChange={(e) => setActors(e.target.value)}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "directors") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Directors</div>
                            <input
                              className="detail-inputs"
                              readOnly={editable}
                              value={directors}
                              onChange={(e) => setDirectors(e.target.value)}
                            />
                          </div>
                        )
                      }
                    } else if (data2?.type === "multiSelect") {
                      if (data2?.name === "genres") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label" key={index2}>{data2?.label}{data2?.required ? <span style={{ color: "red" }}>*</span> : ''}</div>
                            <DynamicMultiSelect
                              editable={editable}
                              options={gernreList}
                              displayValue="name"
                              selectedValues={selectedGenreIds} // Use your state directly here
                              placeholder="Select Genre"
                              setSelectedCategoryIds={setSelectedGenreIds}
                              selectedCategoryIds={selectedGenreIds}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "themes") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Themes</div>
                            <DynamicMultiSelect
                              editable={editable} 
                              options={themeList}
                              displayValue="name"
                              selectedValues={selectedThemeIds} // Use your state directly here
                              placeholder="Select Theme"
                              setSelectedCategoryIds={setSelectedThemeIds}
                              selectedCategoryIds={selectedThemeIds}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "categories") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Categories</div>
                            <DynamicMultiSelect
                              editable={editable}
                              options={categoryOptions}
                              displayValue="name"
                              selectedValues={selectedCategoryIds} // Use your state directly here
                              placeholder="Select Categories"
                              setSelectedCategoryIds={setSelectedCategoryIds}
                              selectedCategoryIds={selectedCategoryIds}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "audioLanguages") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Audio Languages</div>

                            <DynamicMultiSelect
                              editable={editable}
                              options={languageList}
                              displayValue="name"
                              selectedValues={selectedLanguageIds} // Use your state directly here
                              placeholder="Select Language"
                              setSelectedCategoryIds={setSelectedLanguageIds}
                              selectedCategoryIds={selectedLanguageIds}
                            />
                          </div>
                        )
                      }
                      if (data2?.name === "territories") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Territory</div>

                            <DynamicMultiSelect
                              editable={editable}
                              options={territoryOption}
                              displayValue="niceName"
                              selectedValues={selectedTerritoryIds} // Use your state directly here
                              placeholder="Select Territory"
                              setSelectedCategoryIds={setSelectedTerritoryIds}
                              selectedCategoryIds={selectedTerritoryIds}
                            />
                          </div>
                        )
                      }
                    } else if (data2?.type === "select") {
                      if (data2?.name === "contentGroup") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Content Group</div>
                            <select
                              className="detail-inputs"
                              disabled={editable}
                              name="Content"
                              value={contentGroup}
                              onChange={(e) => setContentGroup(e.target.value)}
                              required
                            >
                              <option value="">Select Content Group</option>
                              {
                                ContentGroup && ContentGroup?.map((item) => {
                                  return (
                                    <option key={item} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        )
                      }
                      if (data2?.name === "ratings") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Rating</div>
                            <select
                              className="detail-inputs"
                              disabled={editable}
                              name="theme"
                              value={ratingvalue}

                              onChange={(e) => {
                                setRating(e.target.value);
                                // Assuming item is defined somewhere or it's part of the scope
                                const selectedItem = ratingList.find(item => item.id == e.target.value);
                                if (videodata?.metadata?.ratingsDescriptor == null || videodata?.metadata?.ratingsDescriptor == undefined) {
                                  setRatingsDescriptor(selectedItem.description);
                                }
                              }}
                              required

                            >
                              <option value="">Select Rating</option>
                              {
                                ratingList && ratingList.map((item, index) => {
                                  return (
                                    <option key={item} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        )
                      }
                      if (data2?.name === "originalLanguage") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Original Language</div>

                            <select
                              className="detail-inputs"
                              disabled={editable}
                              name="theme"
                              value={originalLanguage?.id}
                              onChange={(e) => setOriginalLanguage(e.target.value)}
                              required
                            >
                              <option value="">Select Language</option>
                              {languageList &&
                                languageList?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        )
                      }
                      if (data2?.name === "origin") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Origin</div>
                            <select
                              className="detail-inputs"
                              disabled={editable}
                              name="theme"
                              value={origin}
                              onChange={(e) => setOrigin(e.target.value)}
                              required
                            >
                              <option value="">Select Origin</option>
                              {territoryOption &&
                                territoryOption?.map((item) => {
                                  return (
                                    <option key={item.id} value={item.niceName}>
                                      {item.niceName}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        )
                      }
                    } else if (data2?.type === "textarea") {
                      if (data2.name === "shortSynopsis") {
                        return (
                          <div className="detail-row  col-12">
                            <div className="detail-label">Short Synopsis</div>
                            <textarea
                              className="detail-inputs"
                              readOnly={editable}
                              value={shortSynopsis}
                              onChange={(e) => setShortSynopsis(e.target.value)}
                            />
                          </div>
                        )
                      }
                    } else if (data2?.type === "date") {
                      if (data2.name === "releaseDate") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Release Date</div>

                            <DatePicker
                              className="detail-inputs"
                              selected={releaseDate || null}
                              onChange={(date) => setReleaseDate(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              isClearable={permission}
                              readOnly={editable}
                            />
                          </div>
                        )
                      }
                      if (data2.name === "startDate") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">Start Date</div>
                            <DatePicker
                              className="detail-inputs"
                              selected={startDate || null}
                              onChange={(date) => setStartDate(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              isClearable
                              readOnly={editable}
                            />
                          </div>
                        )
                      }
                      if (data2.name === "endDate") {
                        return (
                          <div className="detail-row ">
                            <div className="detail-label">End Date</div>
                            <DatePicker
                              className="detail-inputs"
                              selected={endDate || null}
                              onChange={(date) => setEndDate(date)}
                              dateFormat="dd/MM/yyyy"
                              placeholderText="dd/mm/yyyy"
                              isClearable
                              minDate={startDate} // Setting the minimum selectable date for the end date picker
                              disabled={!startDate} // Disable the end date picker if start date is not selected
                              readOnly={editable}
                            />
                          </div>
                        )
                      }
                    }
                    return null; // Add a return statement here to avoid issues
                  })}
                </div>
              ))
            }
            <div>
              <button className="default-odd mt-2" onClick={() => closepartition()}>
                Cancel
              </button>
              {
                permission === "edit" ?
                  <button disabled={updatebtn} className="default-even mx-3" onClick={handleButtonClick}>
                    {editable ? "Edit" : videodata?.metadata != null ? "Update" : "Add"}
                  </button>
                  :
                  ''
              }
            </div>
        </div>
      </div>

      {/* <div className="">
        <button className="default-odd" onClick={() => closepartition()}>
          Cancel
        </button>
        {
          permission === "edit"?
          <button disabled={updatebtn} className="default-even mx-3" onClick={handleButtonClick}>
            {editable ? "Edit" : videodata?.metadata != null ? "Update" : "Add"}
          </button>
          :
          ''
        }
      </div> */}
    </div>
  );
}

export default Partitioncontent;
