import React, { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import closeChip from '../../assets/images/close-chip.svg';
import { posterimage } from '../../service/commonFunc';
import Groupicon from "../../assets/images/Group.png";
import ReactHlsPlayer from 'react-hls-player';

const VideoPlayer = ({ videodata, src, token, tokenPath, expires, closepartition, programVideos, databymeta }) => {
  const videoRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const AssetPartnerDetail = JSON.parse(localStorage.getItem("AssetPartnerDetail"));

  useEffect(() => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (Hls.isSupported()) {
        const hls = new Hls();
        const modifiedSrc = modifyUrl(src);
        hls.loadSource(modifiedSrc);
        hls.attachMedia(video);
        hls.on(Hls.Events.ERROR, onHlsError);
        hls.on(Hls.Events.MANIFEST_PARSED, onManifestParsed);
        hls.on(Hls.Events.LEVEL_LOADED, onLevelLoaded);
        return () => {
          hls.destroy();
        };
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = src;
        video.addEventListener("loadedmetadata", () => {
          setIsLoaded(true); // Set the video as loaded
        });
      }
    }
  }, [src, token, tokenPath, expires]);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const modifyUrl = (url) => {
    const newUrl = new URL(url);
    newUrl.searchParams.set("token", token);
    newUrl.searchParams.set("token_path", tokenPath);
    newUrl.searchParams.set("expires", expires);
    return newUrl.toString();
  };

  const modifyFragRelUrl = (url) => {
    // append above query params to url
    const newUrl =
      url + `?token=${token}&token_path=${tokenPath}&expires=${expires}`;
    return newUrl;
  };

  const onHlsError = (_event, data) => {
    console.error("HLS error:", data);
  }

  const onManifestParsed = (_event, data) => {
    // Intercept and modify the URL for each level
    data.levels.forEach((level) => {
      level.url = [modifyUrl(level.url[0])];
    });
    setIsLoaded(true);
  };

  const onLevelLoaded = (_event, data) => {
    // Intercept and modify the URL for each fragment
    data.details.fragments.forEach((fragment) => {
      fragment.relurl = modifyFragRelUrl(fragment.relurl);
      fragment.url = modifyUrl(fragment.url);
    });
  };

  return (
    <div className=" bg-light row">
        <div className="breadcrumbs">
            <ul>
                <li>
                    <a href='/asset'>Asset Management</a>
                </li>
                <li>

                    <a
                        href="/CmsChannelDetail"


                    >{AssetPartnerDetail?.name}</a>
                </li>
                <li className="active">Player</li>
            </ul>
        </div>
        <div className='col-10 '>
            <div className="video-container my-3">
                <div className="video-title">{videodata?.metadata == null ? videodata?.filename : videodata?.metadata?.title}
                    <span className="small-grey-text">  
                        {videodata?.metadata == null
                        ? null
                        : "(" + videodata?.filename + ")"}
                    </span>
                </div>
                <button className="cross-container border-0"  style={{ marginLeft: "auto" }}
                onClick={() => closepartition()}
                >
                    <img style={{width:"12px"}}
                        src={closeChip}
                        className="image"
                    />
                </button>
            </div>
            <div>
                <div className="expand-content w-100 row bg-black p-1">
                <video 
                    ref={videoRef} 
                    controls={true} 
                    autoPlay={true}
                    width="100%"
                    height="720px"
                    style={{ maxWidth: "100%", maxHeight: "56.25vw" }} 
                />
                {!isLoaded && <p>Loading...</p>}
                </div>
            </div>
        </div>
        <div className='col-2 '>
            <div className='right-bar'>
                <div>
                    <div><h3 className='text-center right-head'> Videos </h3></div>
                    <div className='video-channel-list meta scroll-height' style={{height: "75vh" }}>
                        {programVideos &&
                            programVideos.map((data, index) => {
                                let image = posterimage(data)
                                return (
                                    videodata?.filename == data?.filename ? <div className="selected-block block m-auto my-2" onClick={() => databymeta(data)} >
                                        {image == "" ?
                                            <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                            <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image} alt="" /></div>}
                                        <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}</label></div>
                                    </div> : null
                                );
                            })}
                        {programVideos &&
                            programVideos.map((data, index) => {
                                let image = posterimage(data)
                                return (
                                videodata?.filename == data?.filename ? null :
                                    <div className=" block m-auto my-2" onClick={() => databymeta(data)} >
                                        {image == "" ?
                                            <div className="img-block " style={{ overflow: "hidden", width: "100%" }}><img src={Groupicon} alt="" /></div> :
                                            <div className="img-block p-0" style={{ overflow: "hidden" }}><img style={{ width: "100%", height: "auto" }} src={image} alt="" /></div>}
                                        <div className="text-block"><label>{data?.metadata == null ? data.filename : data?.metadata?.title == null ? data.filename : data?.metadata?.title}</label></div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default VideoPlayer;
