import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import DashboardHeader from "../component/dashboard-header";
import SlideBar from "../component/sidebar/sidebar";
import MonitorSidebar from "../component/sidebar/monitorSiderbar";
function CmsLayout() {
  let isMonitor = false;

  let url = window.location.href;
  const location = useLocation(); // Get the current location from react-router-dom
  isMonitor = url.includes("monitor");
  // useEffect(() => {
  //   url = window.location.href;
  //   isMonitor =url.includes('monitor');
  //   console.log('nirmal111', isMonitor)
  //   if(isMonitor) {
  //     document.getElementsByTagName('body')[0].classList.add('dark-theme');
  //   } else {
  //     document.getElementsByTagName('body')[0].classList.remove('dark-theme');
  //   }
  //   console.log('Location changed');
  // }, [location]);

  return (
    <>
      {" "}
      <DashboardHeader />
      <div className="main-content">
        {/* nirmal --- {isMonitor} */}
        {isMonitor ? <MonitorSidebar /> : <SlideBar />}

        <Outlet />
      </div>
    </>
  );
}

export default CmsLayout;
